import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Router, RouterLink } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { appRoutes } from '@/app/app.model';
import { AuthService } from '@/app/core/services/auth.service';

const sidenavAnimation = trigger('sidenavAnimation', [
  state('open', style({ width: '80%', opacity: 1 })),
  state('close', style({ width: '0', opacity: 0 })),
  transition('* => close', [animate('150ms')]),
  transition('* => open', [animate('150ms')]),
]);

@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [AsyncPipe, RouterLink],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.css',
  animations: [sidenavAnimation],
})
export class SidenavComponent {
  @Input() isOpen = false;
  @Input() isAdmin = false;
  @Output() sidenavClose = new EventEmitter();
  appRoutes: typeof appRoutes = appRoutes;
  authService = inject(AuthService);
  router = inject(Router);
  username$ = this.authService.getDisplayUsername();

  redirect(route?: string) {
    this.router.navigate([route]);
    this.sidenavClose.emit();
  }

  logout() {
    this.authService.destroyTokenAndRedirect();
  }
}
