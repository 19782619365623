<div id="main-header" data-title="logo" data-icons='"menubtn"'>
  @if (isDashboard || isAdmin) {
    <app-sidenav
      [isOpen]="headerService.isSidenavOpen"
      (sidenavClose)="headerService.isSidenavOpen = false"
      [isAdmin]="isAdmin"
    ></app-sidenav>
  }
  <header>
    <div class="container header-container p-y-5">
      <div class="row vertical-align">
        <div
          class="col-sm-2 col-xs-3 visible-sm text-right visible-xs buttons-container m-0"
          id="miniNavDiv"
        >
          <button
            id="menubtn"
            type="button"
            role="button"
            aria-label="Toggle Navigation"
            [ngStyle]="
              isDashboard || isAdmin
                ? { display: 'block' }
                : { display: 'none' }
            "
            class="lines-button mobileNavbutton"
            (click)="headerService.openSidenav()"
          >
            <span class="lines"></span>
          </button>
          <button
            id="backbtn"
            type="button"
            class="btn btn-small header-icon btn-default back-button"
            [ngStyle]="
              isDashboard || isAdmin
                ? { display: 'none' }
                : { display: 'block' }
            "
            (click)="headerService.goBack()"
          >
            <i class="fa fa-chevron-left"></i>
          </button>
        </div>
        <div class="col-lg-3 col-md-2 col-sm-10 col-xs-4 m-0 hidden-xs">
          <a class="version towline-logo left" href="{{ appRoutes.DASHBOARD }}">
            <img src="assets/images/towlogoaloneMD.png" alt="logo" />
          </a>
        </div>
        <div class="col-lg-3 col-md-2 col-sm-10 col-xs-6 m-0 title visible-xs">
          <a
            class="version towline-logo xs-logo"
            style=""
            href="{{ appRoutes.DASHBOARD }}"
          >
            <img src="assets/images/towlogoaloneMD.png" alt="logo-xs" />
          </a>
        </div>
        <div
          id="header-main-dropdown"
          class="col-lg-3 col-md-2 col-sm-10 col-xs-3 m-0 icon-container visible-xs"
        >
          <ng-content select="[mobile-icons]"></ng-content>
        </div>
        <div
          class="col-lg-8 col-lg-offset-1 col-md-9 col-md-offset-1 col-sm-12 col-xs-12 header-nav-vertical-center hidden-xs"
          id="navDiv"
          style="height: 100%"
        >
          <nav
            id="navigation"
            class="header-nav-vertical-center nav-display-table"
          >
            <ul class="nav">
              <li>
                <button
                  title="Dashboard"
                  type="button"
                  class="link-button"
                  (click)="redirectTo(appRoutes.DASHBOARD)"
                >
                  Dashboard
                </button>
              </li>
              <li>
                <button
                  title="Reports"
                  type="button"
                  class="link-button"
                  (click)="redirectTo(appRoutes.REPORT_CENTER)"
                >
                  Reports
                </button>
              </li>
              <li>
                <button
                  (click)="redirectTo(appRoutes.MAP)"
                  class="link-button"
                  title="Map"
                >
                  Map
                </button>
              </li>
              <li>
                <a
                  href="https://ingrambarge.com/resc.php"
                  target="_blank"
                  title="Contact"
                  onclick="ga('send', 'event', 'Resources', 'clicked');"
                >
                  Resources
                </a>
              </li>
              <li>
                <button
                  title="Settings"
                  type="button"
                  class="link-button"
                  (click)="redirectTo(appRoutes.SETTINGS)"
                >
                  Settings
                </button>
              </li>
              <li class="dropdown tdropDown dropdown-menu-right">
                <div class="currentUser">
                  <span class="fa fa-user fa-lg"></span>
                  <div class="userInformation">
                    <button
                      class="btn btn-link tdropDown username"
                      data-toggle="dropdown"
                      type="button"
                      (click)="headerService.setOpenUserSelect()"
                    >
                      <span class="nameText">
                        {{ headerService.username$ | async }}
                      </span>
                      <i class="fa fa-caret-down"></i>
                    </button>
                    <ul
                      id="headerdropdownmenu"
                      class="dropdown-menu pull-right tdropDown"
                      role="menu"
                      [style.display]="
                        headerService.userSelect ? 'block' : 'none'
                      "
                    >
                      <li>
                        @if (isAdmin) {
                          <a
                            role="button"
                            (click)="redirectTo(appRoutes.ADMIN_PAGE)"
                            id="Admin"
                            class="white-font white-hover cursor-pointer"
                          >
                            Admin
                          </a>
                        }
                        <a
                          role="button"
                          (click)="changePassword()"
                          id="ChangePassBtn"
                          class="white-font white-hover cursor-pointer"
                        >
                          Change Password
                        </a>
                      </li>
                      <li class="white-font white-hover">
                        <a
                          role="button"
                          class="btnLogout white-font white-hover cursor-pointer"
                          (click)="authService.destroyTokenAndRedirect()"
                        >
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <!-- only for desktop, for to mobile it is in the sidenav -->
                <a role="button" (click)="redirectTo(appRoutes.SEARCH)">
                  <i class="fa fa-search fa-lg"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <!-- end 12 -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </header>
</div>
