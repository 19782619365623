// first argument corresponds to message

import { defaultLocale } from '@/app/constants/config';

// second argument onwards work as with any console method
export const log = (...args: (string | number | object)[]): void => {
  console.log(
    `%c[%s] ${args[0]}`,
    'color: white; background-color: cornflowerblue; font-family: monospace; font-size: 2em;',
    new Date().toLocaleTimeString(defaultLocale, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      fractionalSecondDigits: 3,
    }),
    ...args.splice(1),
  );
};

export const warning = (...args: (string | number | object)[]): void => {
  console.warn(
    `%c[%s] ${args[0]}`,
    'color: white; background-color: chocolate; font-family: monospace; font-size: 2em;',
    new Date().toLocaleTimeString(defaultLocale, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      fractionalSecondDigits: 3,
    }),
    ...args.splice(1),
  );
};

export const advise = (...args: (string | number | object)[]): void => {
  console.log(
    `%c[%s] ⚠️ Attention ⚠️ - ${args[0]}`,
    'color: white; background-color: chocolate; font-family: monospace; font-size: 2em;',
    new Date().toLocaleTimeString(defaultLocale, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      fractionalSecondDigits: 3,
    }),
    ...args.splice(1),
  );
};
