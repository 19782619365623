import { inject, Injectable } from '@angular/core';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { environment } from '@/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  TowlineAvailableConfigs,
  TowlineConfiguration,
  TowlineToken,
} from '@/types';
import {
  defaultLifetime,
  jsoConfig,
  selectedProvider,
} from '@/app/constants/config';

import {
  checkTokens,
  getState,
  getTokens,
  hasScope,
  updateTokens,
} from '../utils/TokenStorage.util';
import { advise, log } from '../utils/FormattedConsole.util';
import { getNowAsEpochInSeconds } from '../utils/DateTime.util';
import { createNewTokenRequest } from '../utils/Session.util';
import { appRoutes } from '@/app/app.model';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private TOKEN_KEY = `token.towline.${environment.name}.ingram`;
  private USER_KEY = `user.towline.${environment.name}.ingram`;
  private ORIGINAL_USER_KEY = `original.user.towline.${environment.name}.ingram`;
  private tokenSubject = new BehaviorSubject<string | null>(null);
  private usernameSubject = new BehaviorSubject<string | null>(null);
  private window = inject(Window);
  private jwtHelper = inject(JwtHelperService);
  private router = inject(Router);
  public currentToken$ = this.tokenSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public isAuthenticated$ = this.currentToken$.pipe(
    map((token) => Boolean(token)),
  );

  getToken() {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  setToken(token: string) {
    this.tokenSubject.next(token);
  }

  saveToken(token: string) {
    localStorage.setItem(this.TOKEN_KEY, token);
    this.tokenSubject.next(token);
  }

  destroyToken() {
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.removeItem(this.ORIGINAL_USER_KEY);
    localStorage.removeItem(this.USER_KEY);
    localStorage.clear();
  }

  cleanToken() {
    this.tokenSubject.next(null);
    this.usernameSubject.next(null);
  }

  destroyTokenAndRedirect() {
    this.destroyToken();
    const authBase = environment.authBase;
    if (authBase) {
      const RedirectURI = window.location.href;
      const url = `${authBase}/Signout?ReturnUrl=${RedirectURI}&ReturnName=Towline&client_id=TowlineWeb`;
      this.window.location.href = url;
    } else {
      this.cleanToken();
      this.router.navigate([appRoutes.ROOT]);
    }
  }

  saveUsername(username: string) {
    localStorage.setItem(this.USER_KEY, username);
  }

  saveOriginUsername(username: string) {
    localStorage.setItem(this.ORIGINAL_USER_KEY, username);
  }

  getOriginUsername() {
    return localStorage.getItem(this.ORIGINAL_USER_KEY);
  }

  nextUsername(username: string) {
    this.usernameSubject.next(username);
  }

  getUsername() {
    return localStorage.getItem(this.USER_KEY);
  }

  getCurrentUsername() {
    return this.usernameSubject.pipe(
      map((username) => username ?? this.getUsername() ?? ''),
    );
  }

  getDisplayUsername() {
    return this.usernameSubject.pipe(
      map((username) => username?.split('@')[0]),
    );
  }

  getApplicationId() {
    return of('2');
  }

  getPreferenceCode() {
    return of('ETATHRESHOLDHOURS');
  }

  goToAuthLogin() {
    createNewTokenRequest(this.window);
  }

  addToken(providerId: string, newToken: TowlineToken): void {
    this.saveToken(newToken.access_token);
    const tokenDecoded = this.jwtHelper.decodeToken(newToken.access_token);
    this.saveUsername(tokenDecoded.sub);
    const originalToken = this.getOriginUsername();
    if (!originalToken) {
      this.saveOriginUsername(tokenDecoded.sub);
    }
    this.nextUsername(tokenDecoded.sub);
    const tokens: TowlineToken[] = getTokens(providerId);
    const validTokens: TowlineToken[] = checkTokens(tokens);
    validTokens.push(newToken);
    updateTokens(providerId, validTokens);
  }

  createSessionFromHash(): boolean {
    // TODO: use Angular Router API
    const { hash }: { hash: string } = this.window.location;

    if (!hash) {
      log('No hash was found. Assuming a new access to the site.');
      return false;
    }

    if (hash.indexOf('access_token') === -1) {
      log('No access token found in the hash. This is unexpected behavior.');
      log('Current hash for reference: %s', hash);
      return false;
    }

    log('Hash found: %s', hash);
    advise(
      'Since IDP blocks Vercel domains the hash has to be manually pasted in the URL.',
    );

    const now: number = getNowAsEpochInSeconds();

    // [dberruezo] make iteration, typescript friendly
    const jsoConfigList: TowlineConfiguration[] = Object.values(jsoConfig);

    const defaultJsoConfig: TowlineConfiguration = {
      ...(jsoConfig[selectedProvider as keyof TowlineAvailableConfigs] ||
        jsoConfigList.find((config: TowlineConfiguration) => config.isDefault)),
    };

    const queryParamsString = hash.replace('#', '');
    const urlToken = new URLSearchParams(queryParamsString);
    const state = {
      ...(urlToken.get('state')
        ? getState(urlToken.get('state') as string)
        : {}),
    };
    const selectedConfig: TowlineConfiguration = {
      ...(state.providerID
        ? jsoConfig[state.providerID as keyof TowlineAvailableConfigs]
        : defaultJsoConfig),
    };

    log('The IDP configuration for parsed hash data is the following:', {
      ...selectedConfig,
    });

    if (selectedConfig.scope) {
      state.scopes = [...selectedConfig.scope];
    }

    const currentTokenScope = urlToken.get('scope')
      ? (urlToken.get('scope') as string).split(' ')
      : state.scopes
        ? [...state.scopes]
        : [];

    const currentTokenExpiration = urlToken.get('expires_in')
      ? Number.parseInt(urlToken.get('expires_in') as string, 10)
      : null;

    const currentToken: TowlineToken = {
      access_token: urlToken.get('access_token') as string,
      state: urlToken.get('state') as string,
      expires_in: currentTokenExpiration,
      token_type: urlToken.get('token_type') as string,
      scopes: currentTokenScope,
      scope: urlToken.get('scope') || '',
    };

    if (currentToken.expires_in !== null) {
      currentToken.expires = now + currentToken.expires_in;
    } else if (
      selectedConfig.default_lifetime &&
      typeof selectedConfig.default_lifetime !== 'boolean'
    ) {
      currentToken.expires = now + selectedConfig.default_lifetime;
    } else if (
      selectedConfig.permanent_scope &&
      !hasScope(currentToken, selectedConfig.permanent_scope)
    ) {
      currentToken.expires = now + defaultLifetime;
    } // [dberruezo] any other condition makes the token permanent

    this.addToken(selectedProvider, currentToken);

    log('Successfully obtained and saved a session token.', {
      ...currentToken,
    });

    // TODO: use Angular Router API
    window.location.hash = state.restoreHash || '';

    return true;
  }

  getIsAdmin = () => {
    if (environment.mocks) {
      return true;
    }
    try {
      const accessToken = this.getToken();
      const decoded = this.jwtHelper.decodeToken(accessToken!);
      const role =
        'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

      // If Admin is in the roles array part of the object
      return !!decoded[role].includes('Towline Admins');
    } catch (err) {
      return false;
    }
  };
}
