import isDate from 'lodash/isDate';
import { Nullable } from '@/types';
import { defaultLocale } from '@/app/constants/config';

export const getNowAsEpochInSeconds = (): number => {
  return Math.round(new Date().getTime() / 1000.0);
};

export const formatDateTime = (input: unknown): Nullable<string> => {
  const date = new Date(input as string);
  if (!isDate(date) || !input) {
    return null;
  }
  const dateTime = date.toLocaleTimeString(defaultLocale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC',
  });

  return dateTime;
};

export const formatDateTime24 = (input: unknown): Nullable<string> => {
  const date = new Date(input as string);
  if (!isDate(date) || !input) {
    return null;
  }
  const dateTime = date.toLocaleTimeString(defaultLocale, {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC',
  });

  return dateTime;
};

export const formatDate = (input: unknown): Nullable<string> => {
  const date = new Date(input as string);
  if (!isDate(date) || !input) {
    return null;
  }
  return date.toLocaleDateString(defaultLocale);
};
