import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { bargeSortColumns } from '@/app/core/models/barges.model';
import { boatSortColumns } from '@/app/core/models/boats.model';

import { AuthService } from '../../services/auth.service';
import { SidenavComponent } from '../sidenav/sidenav.component';
import { HeaderService } from './header.service';
import { appRoutes } from '@/app/app.model';
import { environment } from '@/environments/environment';

@Component({
  styleUrl: './header.component.css',
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  imports: [SidenavComponent, AsyncPipe, NgStyle, NgIf],
})
export class HeaderComponent implements OnInit {
  public readonly appRoutes = appRoutes;
  headerService = inject(HeaderService);
  authService = inject(AuthService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  @Input() hasSort = false;
  @Input() columns!: typeof bargeSortColumns | typeof boatSortColumns;
  @Output() handleSortEmit: EventEmitter<string> = new EventEmitter();
  protected readonly Object = Object;
  isDashboard = false;
  isAdmin = false;

  changePassword() {
    const RedirectURI = window.location.href;
    if (environment.authBase) {
      const changePassUrl =
        environment.authBase +
        '/ChangePassword?ReturnName=Towline&client_id=TowlineWeb&ReturnUrl=' +
        RedirectURI;
      changePassUrl.replace('#', '');
      window.location.href = changePassUrl;
    }
  }
  redirectTo(route: string) {
    this.router.navigate([route]);
    this.headerService.userSelect = false;
  }
  ngOnInit() {
    this.route.url.subscribe((url) => {
      this.isDashboard = url[1].path === appRoutes.DASHBOARD.split('/')[1];
    });
    this.isAdmin = this.authService.getIsAdmin();
  }
}
