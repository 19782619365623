import { Component } from '@angular/core';
import { appRoutes } from '@/app/app.model';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  public readonly appRoutes = appRoutes;
}
