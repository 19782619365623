import { inject, Injectable } from '@angular/core';
import { AuthService } from '@/app/core/services/auth.service';
import { appRoutes } from '@/app/app.model';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class HeaderService {
  userSelect = false;
  isSidenavOpen = false;
  authService = inject(AuthService);
  username$ = this.authService.getDisplayUsername();
  router = inject(Router);

  setOpenUserSelect = () => {
    this.userSelect = !this.userSelect;
  };
  openSidenav() {
    this.isSidenavOpen = true;
  }
  goBack() {
    if (this.router.url.indexOf(appRoutes.SETTINGS_FORMS_ROOT) !== -1) {
      this.router.navigate([appRoutes.SETTINGS]);
      return;
    }

    this.router.navigate([appRoutes.DASHBOARD]);
  }
}
