export const environment = {
  mocks: false,
  name: 'development',
  authBase: 'https://authoritydev.apps.ingram.com',
  authorizationUrl:
    'https://authoritydev.apps.ingram.com/Towline/oauth/authorize',
  apiUrl: 'https://towlinedev.apps.ingram.com',
  reportViewUrl: 'https://towlinedev.apps.ingram.com/reportdelivery/View',
  bingMapKey:
    'Ahthmk1LtKvLLCPWf65OmhbLAocld6-78AxqIMEcsMtzXU4b_rXM-_PxNBN8RHYb',
};
