<div id="sidenav" [@sidenavAnimation]="isOpen ? 'open' : 'close'">
  <div class="col-sm-12 col-xs-12">
    <h4 class="sidenav-text user-name">
      {{ username$ | async }}
    </h4>
  </div>
  <div class="col-sm-12 col-xs-12">
    <h4 class="sidenav-text">INGRAM BARGE COMPANY</h4>
  </div>
  <button class="closebtn btn-link" type="button" (click)="sidenavClose.emit()">
    ×
  </button>
  <div class="col-sm-12 col-xs-12 p-y-5">
    <ul class="list-unstyled components sidenav-items">
      <li>
        @if (isAdmin) {
          <button
            type="button"
            (click)="redirect(appRoutes.ADMIN_PAGE)"
            class="btn-link"
          >
            <i class="fa fa-fw fa-user"></i>
            Admin
          </button>
        }
      </li>
      <li>
        <button
          type="button"
          (click)="redirect(appRoutes.DASHBOARD)"
          class="btn-link"
        >
          <i class="fa fa-fw fa-tachometer"></i>
          Dashboard
        </button>
      </li>
      <li>
        <button
          type="button"
          (click)="redirect(appRoutes.REPORT_CENTER)"
          class="btn-link"
        >
          <i class="fa fa-fw fa-file-text-o"></i>
          Reports
        </button>
      </li>
      <li>
        <button
          type="button"
          (click)="redirect(appRoutes.MAP)"
          class="btn-link"
        >
          <i class="fa fa-fw fa-map-marker"></i>
          Map
        </button>
      </li>
      <li>
        <a
          href="https://ingrambarge.com/resc.php"
          target="_blank"
          title="Contact"
          onclick="ga('send', 'event', 'Resources', 'clicked');"
        >
          <i class="fa fa-fw fa-file-text-o"></i>
          Resources
        </a>
      </li>
      <li>
        <button
          type="button"
          (click)="redirect(appRoutes.SEARCH)"
          class="btn-link"
        >
          <i class="fa fa-fw fa-search"></i>
          Search
        </button>
      </li>
      <li>
        <button
          type="button"
          (click)="redirect(appRoutes.SETTINGS)"
          class="btn-link"
        >
          <i class="fa fa-fw fa-wrench"></i>
          Settings
        </button>
      </li>
      <li>
        <button type="button" (click)="logout()" class="btn-link">
          <i class="fa fa-fw fa-user"></i>
          Log off
        </button>
      </li>
    </ul>
  </div>
</div>
