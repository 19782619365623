import { Nullable, TowlineState, TowlineToken } from '@/types';

export const saveState = (stateId: string, request: TowlineState): void => {
  try {
    localStorage.setItem(`state-${stateId}`, JSON.stringify(request));
  } catch (e) {
    const error: Error = <Error>e;

    console.error('There was an error setting the session state.');
    console.error(error.message);
  }
};

export const getState = (stateId: string): TowlineState => {
  const request =
    JSON.parse(localStorage.getItem(`state-${stateId}`) as string) || {};

  localStorage.removeItem(`state-${stateId}`);

  return request;
};

export const updateTokens = (
  providerId: string,
  tokens: TowlineToken[],
): void => {
  try {
    localStorage.setItem(`tokens-${providerId}`, JSON.stringify(tokens));
  } catch (e) {
    const error: Error = <Error>e;

    console.error('There was an error setting the token list.');
    console.error(error.message);
  }
};

export const getTokens = (providerId: string): TowlineToken[] => {
  return (JSON.parse(localStorage.getItem(`tokens-${providerId}`) as string) ||
    []) as TowlineToken[];
};

export const checkTokens = (
  tokens: TowlineToken[],
  requiredScopes: string[] = [],
): TowlineToken[] => {
  const now = Math.round(new Date().getTime() / 1000.0);

  return tokens.filter(({ expires, scopes }) => {
    if (expires && now + 1 > expires) {
      return false;
    }

    if (!scopes || !requiredScopes.length) {
      return true;
    }

    return requiredScopes.filter((scope: string) => scopes.includes(scope));
  });
};

export const clearTokens = (providerId: string): void => {
  localStorage.removeItem(`tokens-${providerId}`);
};

export const hasScope = (token: TowlineToken, singleScope: string): boolean => {
  return token?.scopes.includes(singleScope) || false;
};

export const getFirstValidToken = (
  providerId: string,
  requiredScopes: string[],
): Nullable<TowlineToken> => {
  const tokens: TowlineToken[] = getTokens(providerId);
  const validTokens: TowlineToken[] = checkTokens(tokens, requiredScopes);

  return validTokens.length ? validTokens[0] : null;
};
