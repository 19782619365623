import {
  TowlineAvailableConfigs,
  TowlineConfiguration,
  TowlineRequest,
  TowlineState,
} from '@/types';
import {
  jsoConfig,
  requiredScopes,
  selectedProvider,
} from '@/app/constants/config';
import { saveState } from '@/app/core/utils/TokenStorage.util';
import { v4 as uuidv4 } from 'uuid';
import { advise, log } from '@/app/core/utils/FormattedConsole.util';

export const createNewTokenRequest = (currentTab: Window) => {
  // TODO: use Angular Router API
  const { hash }: { hash: string } = currentTab.location;

  // [dberruezo] make iteration, typescript friendly
  const jsoConfigList: TowlineConfiguration[] = Object.values(jsoConfig);

  const selectedConfig: TowlineConfiguration = {
    ...(jsoConfig[selectedProvider as keyof TowlineAvailableConfigs] ??
      jsoConfigList.find((config: TowlineConfiguration) => config.isDefault)),
  };

  log('The IDP configuration for new token request is the following:', {
    ...selectedConfig,
  });

  const { client_id, redirect_uri, authorization, scope } = {
    ...selectedConfig,
  } as TowlineConfiguration;

  const newStateId: string = uuidv4();

  const tokenRequest: TowlineRequest = {
    response_type: 'token',
    state: newStateId,
    client_id: client_id,
    redirect_uri: redirect_uri,
    scope: scope.join(' '),
  };

  const requestParams = new URLSearchParams(Object.entries(tokenRequest));

  const authorizationRequestUrl = `${authorization}?${requestParams.toString()}`;

  const newState: TowlineState = {
    ...tokenRequest,
    providerID: selectedProvider,
    ...(hash ? { restoreHash: hash } : {}),
    ...(requiredScopes ? { scopes: requiredScopes } : {}),
  };

  log('New token request created. %o', { ...newState });

  saveState(newStateId, newState);

  advise(
    'Redirecting to %s for authorization. Verify your network access is correctly configured.',
    authorizationRequestUrl,
  );

  window.location.assign(authorizationRequestUrl);
};
