<div id="footer" class="footer">
  <div class="container" style="padding-top: 20px; text-align: center">
    <div>
      <nav>
        <div class="col-md-4 col-s-4 col-xs-12" style="min-width: 33%">
          <ul
            class="nav white-font"
            id="footerNav"
            style="font-family: robotolight, sans-serif; display: inline-block"
          >
            <li>
              <a
                class="white-font"
                href="{{ appRoutes.DASHBOARD }}"
                title="Dashboard"
              >
                Dashboard
              </a>
            </li>
            <li>
              <a
                class="white-font"
                href="{{ appRoutes.REPORT_CENTER }}"
                title="Reports"
              >
                Reports
              </a>
            </li>
            <li>
              <a class="white-font" href="{{ appRoutes.MAP }}" title="Map">
                Map
              </a>
            </li>
            <li>
              <!--<a class="white-font" href="https://ingrambarge.com/contact.html" title="Contact">Contact</a>-->
              <a
                class="white-font"
                href="https://ingrambarge.com/resc.php"
                target="_blank"
                title="Resources"
                onclick="ga('send', 'event', 'Resources', 'clicked');"
              >
                Resources
              </a>
            </li>
            <li>
              <a
                class="white-font"
                href="{{ appRoutes.SETTINGS }}"
                title="Settings"
              >
                Settings
              </a>
            </li>
          </ul>
        </div>

        <div
          class="col-md-4 col-s-4 col-xs-12"
          style="min-width: 33%; display: inline-block"
        >
          -=
          <a
            class="btn navbar-btn white-hover"
            href="https://www.facebook.com/IngramBarge"
          >
            <i class="fa fa-facebook"></i>
          </a>
          <a
            class="btn navbar-btn white-hover"
            href="https://www.linkedin.com/company/ingram-barge-company"
          >
            <i class="fa fa-linkedin"></i>
          </a>
          <a
            class="btn navbar-btn white-hover"
            href="http://instagram.com/ingrambarge"
          >
            <i class="fa fa-instagram"></i>
          </a>
          <a
            class="btn navbar-btn white-hover"
            href="https://www.youtube.com/channel/UCfdKxHFy3ktODRGDvhEiASQ"
          >
            <i class="fa fa-youtube-play"></i>
          </a>
          <a
            class="btn navbar-btn white-hover"
            [href]="appRoutes.RESOURCES_PDF"
            target="_blank"
          >
            <i class="fa fa-question-circle"></i>
          </a>
          =-
        </div>

        <div
          id="copyright"
          class="white-font col-md-4 col-s-1 col-xs-12"
          style="
            font-size: 12px;
            display: inline-block;
            padding-top: 4px;
            min-width: 33%;
          "
        >
          © 1990, 2014 - Ingram Barge Company - v1.0.0.0
        </div>
      </nav>
    </div>
  </div>
</div>
