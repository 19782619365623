import { environment } from '@/environments/environment';
import { TowlineAvailableConfigs } from '@/types';
import { appRoutes } from '../app.model';

export const authorizationUrl = environment.authorizationUrl;

export const jsoConfig: TowlineAvailableConfigs = {
  towline: {
    client_id: 'TowlineWeb',
    redirect_uri: `${window.location.origin}/${appRoutes.DASHBOARD}`,
    scope: ['User'],
    authorization: authorizationUrl,
    isDefault: true,
    //presenttoken: "qs"
  },
};

export const defaultLifetime = 3600;

export const selectedProvider = 'towline';
export const requiredScopes = ['User'];

export const bingMapKey: string = environment.bingMapKey;

export const defaultLocale = 'en-US';
export const dateFormat = 'M/d/yy, h:mm';
export const utcTimezone = 'UTC';
